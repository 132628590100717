import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Masonry from 'react-masonry-css'
import { Hero } from '../acf/Hero'
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/htmlParse';
import { getPageSlugFromWpLink } from '../utils/helpers';
import './sitemap.scss';

const Sitemap = ({ data }) => {
  const { allWordpressPage, site } = data;
  const { siteMetadata: { wordpressUrl, siteTitle } } = site;
  const parentPages = allWordpressPage.nodes.filter(page => page.parent_element === null);
  const sitemapItems = parentPages.map(item => {
    const children = allWordpressPage.nodes.filter(page => {
      if (!page.parent_element) return null;
      return page.parent_element.slug === item.slug;
    });
    return children.length > 0 ? {...item, children} : item;
  });
  /**
   * For masonry-css layout, set responsive column amount
   */
  const breakpointColumnsObj = {
    default: 4,
    1300: 3,
    1100: 1,
  };

  /**
   * Filter these from appearing within the 'visual' sitemap.
   */
  const EXCLUDED_PAGE_SLUGS = [
    'all-components',
    'filter',
    'blog',
  ]


  return (
    <Layout className="sitemap-page-template">
      <SEO title={`Sitemap | ${siteTitle}`} />
      <section className="sitemap-hero">
        <h1>Sitemap</h1>
      </section>
      <section className="sitemap">
        <div className="wrapper">
            <Masonry
            breakpointCols={breakpointColumnsObj}
            className="sitemap-grid"
            columnClassName="sitemap-item">
            {sitemapItems.filter(item => !EXCLUDED_PAGE_SLUGS.includes(item.slug)).map(item => {
              return (
                <>
                  <Link to={getPageSlugFromWpLink(item.link, wordpressUrl)}>{decodeEntities(item.title)}</Link>
                  {item.children && (
                    <div className="sitemap-item-children">
                      {item.children
                        .filter(child => !EXCLUDED_PAGE_SLUGS.includes(child.slug))
                        .map(child => {
                          return (
                            <div className="sitemap-item-child">
                              <Link
                                to={getPageSlugFromWpLink(child.link, wordpressUrl)}
                                >
                                {decodeEntities(child.title)}
                              </Link>
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </>
                );
              })}
            </Masonry>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query SitemapQuery {
    site {
      siteMetadata {
        siteTitle: title
        wordpressUrl
      }
    }
    allWordpressPage(filter: {slug: {ne: "gatsby-demo"}}, sort: {fields: [wordpress_id]}) {
      nodes {
        title
        slug
        link
        parent_element {
          slug
        }
      }
    }
  }
`

export default Sitemap;